import type { TFunction } from 'i18next';
import every from 'lodash-es/every';
import { getLogger } from 'loglevel';
import { z } from 'zod';
import type { ZodObject, ZodRawShape } from 'zod';

import { getPropertyNameCaseInsensitive } from '@apple/utils/object';

import type { errorUtil } from '../../../node_modules/zod/lib/helpers/errorUtil';
import type { ValidationRule } from './models';

const log = getLogger('validation');

export type extendSchemaWithServerRulesProps<T extends ZodObject<ZodRawShape>> = {
	existingSchema: T;
	rules: ValidationRule[];
	whitelist: string[];
	t: TFunction<'zod', undefined>;
	ignoreLocalization?: boolean;
};

export function extendSchemaWithServerRules<T extends ZodObject<ZodRawShape>>({
	existingSchema,
	rules,
	whitelist,
	t,
	ignoreLocalization = false,
}: extendSchemaWithServerRulesProps<T>): T {
	const schemaExtensions: ZodRawShape = {};

	rules.forEach(rule => {
		const fieldName = getPropertyNameCaseInsensitive(existingSchema.shape, rule.fieldName);
		if (!fieldName) {
			const message = `Field ${rule.fieldName} not found in schema`;
			log.error(message);
			throw new Error(message);
		}

		const fieldSchema = schemaExtensions[fieldName] ?? existingSchema.shape[fieldName];
		if (!fieldSchema) {
			throw new Error(
				`Field ${fieldName} not found in schema ${existingSchema._def.typeName}`,
			);
		}

		schemaExtensions[fieldName] = extendSchemaWithServerRule(
			fieldSchema,
			fieldName,
			rule,
			whitelist,
			t,
			ignoreLocalization,
		);
	});

	// Merge the existing schema with the new extensions
	const extendedSchema = existingSchema.merge(z.object(schemaExtensions)) as T;

	log.debug('Extended schema:', { from: existingSchema.shape, to: extendedSchema.shape });

	return extendedSchema;
}

function extendSchemaWithServerRule(
	fieldSchema: z.ZodTypeAny,
	fieldName: string,
	rule: ValidationRule,
	whitelist: string[],
	t: TFunction<'zod', undefined>,
	ignoreLocalization = false,
): z.ZodTypeAny {
	log.debug('Extending field with server validation rule:', fieldName, rule);

	//TODO: Implement group rules?
	//TODO: Implement visibility rule?
	const customMessage =
		rule.validationMessage !== null && rule.validationMessage !== undefined
			? {
					message: ignoreLocalization
						? rule.validationMessage
						: t(`errors.${rule.validationMessage}`),
				}
			: undefined;

	fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
		applyRequiredRule(unwrappedSchema, rule, fieldName, customMessage),
	);
	fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
		applyMinValueRule(unwrappedSchema, rule, fieldName, customMessage),
	);
	fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
		applyMaxValueRule(unwrappedSchema, rule, fieldName, customMessage),
	);
	fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
		applyMinLengthRule(unwrappedSchema, rule, fieldName, customMessage),
	);
	fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
		applyMaxLengthRule(unwrappedSchema, rule, fieldName, customMessage),
	);
	fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
		applyRegexRule(unwrappedSchema, rule, fieldName, customMessage),
	);
	fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
		applyAcceptedValuesRule(unwrappedSchema, rule, fieldName, customMessage),
	);
	if ((rule.acceptedValues?.length ?? 0) === 0) {
		fieldSchema = ensureUnwrapped(fieldSchema, unwrappedSchema =>
			applyWhitelistRule(unwrappedSchema, rule, fieldName, whitelist, customMessage),
		);
	}

	return fieldSchema;
}

function applyRequiredRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	_customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (
		rule.isRequired &&
		(fieldSchema instanceof z.ZodArray || fieldSchema instanceof z.ZodString)
	) {
		fieldSchema = fieldSchema.min(1);
		log.debug(`    ${fieldName}: applying required validation rule to field`);
		return fieldSchema;
	}

	if (!rule.isRequired) {
		log.debug(`    ${fieldName}: applying optional validation rule to field`);
		fieldSchema = fieldSchema.optional();
		return fieldSchema;
	}

	return fieldSchema;
}

function applyMinValueRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (rule.minValue == undefined) {
		return fieldSchema;
	}

	if (fieldSchema instanceof z.ZodNumber) {
		log.debug(
			`    ${fieldName}: applying minValue '${rule.minValue}' validation rule to number field`,
		);
		fieldSchema = fieldSchema.min(rule.minValue, customMessage);

		return fieldSchema;
	}

	log.warn(
		`    ${fieldName}: ignoring minimum value validation rule for field of type ${fieldSchema._def.typeName}. Minimum value is only supported for number fields.`,
	);
	return fieldSchema;
}

function applyMaxValueRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (rule.maxValue == undefined) {
		return fieldSchema;
	}

	if (fieldSchema instanceof z.ZodNumber) {
		log.debug(
			`    ${fieldName}: applying maxValue '${rule.maxValue}' validation rule to number field`,
		);
		fieldSchema = fieldSchema.max(rule.maxValue, customMessage);

		return fieldSchema;
	}

	log.warn(
		`    ${fieldName}: ignoring maximum value validation rule for field of type ${fieldSchema._def.typeName}. Maximum value is only supported for number fields.`,
	);
	return fieldSchema;
}

function applyMinLengthRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (rule.minLength == undefined) {
		return fieldSchema;
	}

	if (fieldSchema instanceof z.ZodString) {
		log.debug(
			`    ${fieldName}: applying minLength '${rule.minLength}' validation rule to string field`,
		);
		return fieldSchema.min(rule.minLength, customMessage);
	}

	if (fieldSchema instanceof z.ZodArray) {
		log.debug(
			`    ${fieldName}: applying minLength '${rule.minLength}' validation rule to array field`,
		);
		return fieldSchema.min(rule.minLength, customMessage);
	}

	log.warn(
		`    ${fieldName}: ignoring minimum length validation rule for field of type ${fieldSchema._def.typeName}. Minimum length is only supported for string and array fields.`,
	);
	return fieldSchema;
}

function applyMaxLengthRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (rule.maxLength == undefined) {
		return fieldSchema;
	}

	if (fieldSchema instanceof z.ZodString) {
		log.debug(
			`    ${fieldName}: applying maxLength '${rule.maxLength}' validation rule to string field`,
		);
		return fieldSchema.max(rule.maxLength, customMessage);
	}

	if (fieldSchema instanceof z.ZodArray) {
		log.debug(
			`    ${fieldName}: applying maxLength '${rule.maxLength}' validation rule to array field`,
		);
		return fieldSchema.max(rule.maxLength, customMessage);
	}

	log.warn(
		`    ${fieldName}: ignoring maximum length validation rule for field of type ${fieldSchema._def.typeName}. Maximum length is only supported for string and array fields.`,
	);
	return fieldSchema;
}

function applyRegexRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (rule.regex == undefined) {
		return fieldSchema;
	}

	if (fieldSchema instanceof z.ZodString) {
		log.debug(
			`    ${fieldName}: applying regex '${rule.regex}' validation rule to string field`,
		);
		return fieldSchema.regex(new RegExp(rule.regex), customMessage);
	}

	log.warn(
		`    ${fieldName}: ignoring regex validation rule for field of type ${fieldSchema._def.typeName}. Regex is only supported for string fields.`,
	);
	return fieldSchema;
}

function applyAcceptedValuesRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (rule.acceptedValues == undefined || rule.acceptedValues.length <= 0) {
		return fieldSchema;
	}

	if (fieldSchema instanceof z.ZodArray || fieldSchema instanceof z.ZodString) {
		const acceptedValues = new Set(rule.acceptedValues.map(av => av.Key));
		log.debug(
			`    ${fieldName}: applying acceptedValues '${[...acceptedValues].join(', ')}' validation rule to string field`,
		);

		return fieldSchema instanceof z.ZodArray
			? fieldSchema.refine(
					val => val.every(v => acceptedValues.has(String(v))),
					customMessage,
				)
			: fieldSchema.refine(val => acceptedValues.has(val), customMessage);
	}

	log.warn(
		`    ${fieldName}: ignoring accepted values validation rule for field of type ${fieldSchema._def.typeName}. Accepted values is only supported for string and string[] fields.`,
	);
	return fieldSchema;
}

function applyWhitelistRule(
	fieldSchema: z.ZodTypeAny,
	rule: ValidationRule,
	fieldName: string,
	whitelist: string[],
	customMessage: errorUtil.ErrMessage | undefined,
): z.ZodTypeAny {
	if (!rule.checkWhitelist) {
		return fieldSchema;
	}

	if (fieldSchema instanceof z.ZodString) {
		return fieldSchema.refine(
			val => containsOnlyWhitelistedCharacters(val, whitelist),
			customMessage,
		);
	}

	if (fieldSchema instanceof z.ZodEnum) {
		log.debug(`${fieldName}: ignoring whitelist check.`);
		return fieldSchema;
	}

	log.warn(
		`    ${fieldName}: ignoring whitelist validation rule for field of type ${fieldSchema._def.typeName}. Whitelist is only supported for string fields.`,
	);
	return fieldSchema;
}

function containsOnlyWhitelistedCharacters(value: string, whitelist: string[]) {
	if (!value || !whitelist || whitelist.length === 0) {
		return true;
	}

	return every(value, char => whitelist.includes(char.toLowerCase()));
}

type ModifierFunction<T extends z.ZodTypeAny> = (schema: T) => T;

function ensureUnwrapped<T extends z.ZodTypeAny>(
	schema: T,
	modifier: ModifierFunction<T>,
): z.ZodTypeAny {
	let wasOptional = false;
	let wasNullable = false;
	let wasPromise = false;

	let modifiedSchema: z.ZodTypeAny = schema;
	while (
		modifiedSchema instanceof z.ZodOptional ||
		modifiedSchema instanceof z.ZodNullable ||
		modifiedSchema instanceof z.ZodPromise ||
		modifiedSchema instanceof z.ZodBranded
	) {
		if (modifiedSchema instanceof z.ZodOptional) {
			wasOptional = true;
			modifiedSchema = modifiedSchema.unwrap() as z.ZodTypeAny;
		}
		if (modifiedSchema instanceof z.ZodNullable) {
			wasNullable = true;
			modifiedSchema = modifiedSchema.unwrap() as z.ZodTypeAny;
		}
		if (modifiedSchema instanceof z.ZodPromise) {
			wasPromise = true;
			modifiedSchema = modifiedSchema.unwrap() as z.ZodTypeAny;
		}
		if (modifiedSchema instanceof z.ZodBranded) {
			throw new Error('Modifying branded schemas is not supported yet');
		}
	}

	modifiedSchema = modifier(modifiedSchema as T);

	if (wasPromise) {
		modifiedSchema = modifiedSchema.promise() as z.ZodTypeAny;
	}
	if (wasNullable) {
		modifiedSchema = modifiedSchema.nullable() as z.ZodTypeAny;
	}
	if (wasOptional) {
		modifiedSchema = modifiedSchema.optional() as z.ZodTypeAny;
	}

	return modifiedSchema;
}
