import log from 'loglevel';

import { configAppInsights } from '@apple/lib/app-insights';
import { configAxios } from '@apple/lib/axios';
import { configDayjs } from '@apple/lib/dayjs';
import { configLocalization } from '@apple/lib/i18next';
import { configLogging } from '@apple/lib/loglevel';
import { getEnv } from '@apple/utils/config/env';

export async function configApp() {
	const env = getEnv();
	const isDev = env.DEV;

	log.info(`Starting ${env.APPLE_APP_TITLE}:`, env);

	const { telemetry } = configAppInsights({
		instrumentationKey: env.APPLE_APPINSIGHTS_INSTRUMENTATION_KEY,
		roleTag: env.APPLE_APPINSIGHTS_ROLE_NAME,
	});

	// FIXME: The default log level is not working... Need to fix (possibly in another PR)
	const logger = configLogging(isDev ? log.levels.TRACE : log.levels.WARN, {
		// In order for each logger to get configured before it is used, each logger must be configured
		// here until we make a better way to create loggers (we should probably make our own logger factory).
		// ⚠️ MAKE SURE THESE ARE SET TO WARN BEFORE MERGING TO MAIN! ⚠️
		// TODO: Find a way to set these in .env.local or .env.development
		auth: log.levels.WARN,
		steps: log.levels.WARN,
		cart: log.levels.WARN,
		shop: log.levels.WARN,
		validation: log.levels.WARN,
		DataTable: log.levels.WARN,
		form: log.levels.WARN,
		announcement: log.levels.WARN,
		'cart-menu': log.levels.WARN,
		'store-events': log.levels.TRACE,
	});
	configDayjs();
	await configLocalization({
		debug: isDev,
	});
	configAxios();

	return {
		telemetry,
		log: logger,
		env,
	};
}
