import { SimpleGrid } from '@mantine/core';

import type { IconElement } from '@apple/assets/icons';
import { CustomIconButton } from '@apple/ui/layouts/components/button-with-icon';
import type { CustomButtonIconProps } from '@apple/ui/layouts/components/button-with-icon';
import { useBreakpoints } from '@apple/ui/layouts/hooks/useBreakpoints';
import { useLeftSidebarCollapsed } from '@apple/ui/shell/contexts/left-sidebar';

export type DashboardCard = Omit<CustomButtonIconProps, 'text' | 'icon' | 'backgroundColor'> & {
	title: string;
	icon: IconElement;
	color: string;
};

type Props = {
	cards: DashboardCard[];
};

export function Dashboard({ cards }: Props) {
	const leftSidebarCollapsed = useLeftSidebarCollapsed();
	const { smOrLarger, xsOrSmaller } = useBreakpoints();
	const gridCols = xsOrSmaller ? 1 : smOrLarger && leftSidebarCollapsed.isCollapsed ? 3 : 2;

	return (
		<SimpleGrid cols={{ base: gridCols, lg: 2 }} py='sm' px='md' mx='xl'>
			{cards.map(({ icon: IconElement, title, color, ...toOptions }, index) => (
				<CustomIconButton
					{...toOptions}
					icon={<IconElement size='1.5rem' c='var(--mantine-primary-color-contrast)' />}
					text={title}
					key={index}
					backgroundColor={color}
				/>
			))}
		</SimpleGrid>
	);
}
