import { Stack, Text, Title } from '@mantine/core';
import { createFileRoute, useMatch } from '@tanstack/react-router';
import { z } from 'zod';

import { requireAuth } from '@apple/features/auth';
import { useTranslation } from '@apple/lib/i18next';
import { TitleLayout } from '@apple/ui/layouts/TitleLayout';
import { NotFoundPage } from '@apple/ui/shell/pages/NotFoundPage';
import { NotAuthorizedError } from '@apple/utils/api/errors';

export const Route = createFileRoute('/_authed/returns_/$orderNumber')({
	errorComponent: ({ error }) => {
		if (error instanceof NotAuthorizedError) {
			return <NotFoundPage />;
		} else {
			throw error;
		}
	},
	parseParams: ({ orderNumber }) => ({
		orderNumber: z.string().parse(orderNumber),
	}),
	beforeLoad: args =>
		requireAuth(args, {
			requirePermission: 'AppleBrandedPrograms.Security.Features.Returns',
		}),
	component: ReturnResultRoute,
});

function ReturnResultRoute() {
	const { t } = useTranslation('returns');

	const match = useMatch({
		from: '/_authed/returns_/$orderNumber',
	});
	const orderNumber = match.params.orderNumber;

	return (
		<TitleLayout title={t('title')}>
			<Stack align='center'>
				<Title order={2}>{t('successMessage.title')}</Title>
				<Text>{t('successMessage.message', { orderNumber })}</Text>
			</Stack>
		</TitleLayout>
	);
}
