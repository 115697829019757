import { Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import camelCase from 'lodash-es/camelCase';

import { requireAuth } from '@apple/features/auth';
import { requestTypeSchema } from '@apple/features/report/support-requests/models';
import type {
	SupportRequestFilters,
	SupportRequestReportListing,
} from '@apple/features/report/support-requests/models';
import { supportRequestOdataQueryOptions } from '@apple/features/report/support-requests/odata.queries';
import { useTranslation } from '@apple/lib/i18next';
import { DataTable, useTableState } from '@apple/ui/data-table';
import { TableLayout } from '@apple/ui/layouts';
import { Link } from '@apple/ui/link';
import { FormattedDate } from '@apple/utils/globalization';

export const Route = createFileRoute('/_authed/_admin/reports/supportRequests')({
	component: SupportRequestsReport,
	beforeLoad: args => {
		requireAuth(args, {
			requirePermission: 'Manzanita.Security.Features.Reports',
		});
	},
});

const defaultFilters: SupportRequestFilters = {};

function SupportRequestsReport() {
	const { t } = useTranslation('reports');
	const search = Route.useSearch();
	const navigate = Route.useNavigate();

	const tableState = useTableState<SupportRequestReportListing, SupportRequestFilters>({
		search,
		navigate,
		defaultFilters,
		defaultSorting: [{ id: 'createdOn', desc: true }],
		fieldMap: [],
	});

	const supportRequestQuery = useQuery(
		supportRequestOdataQueryOptions({
			pagination: tableState.state.pagination,
			filters: tableState.currentFilterData,
		}),
	);

	const table = useReactTable<SupportRequestReportListing>({
		data: supportRequestQuery.data.rows,
		rowCount: supportRequestQuery.data.totalRowCount,
		...tableState,
		enableFilters: true,
		enableColumnFilters: true,
		enableSorting: true,
		manualFiltering: true,
		manualSorting: true,
		manualPagination: true,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: (table, columnId) => {
			switch (columnId) {
				case 'requestType':
					return () => new Map(requestTypeSchema._def.values.map(x => [x, 1]));
				default:
					return getFacetedUniqueValues<SupportRequestReportListing>()(table, columnId);
			}
		},
		columnResizeMode: 'onChange',
		columns: [
			{
				accessorKey: 'requestType',
				header: t('supportRequests.fields.requestType'),
				size: 100,
				filter: {
					group: t('supportRequests.filterGroups.details'),
					label: t('supportRequests.filters.requestType'),
					variant: 'select',
					getFilterDisplayValue: value =>
						t(`help:requestTypes.${camelCase(value as string)}`),
				},
				cell: ({ row }) => (
					<Link
						to='/reports/supportRequests/$emailId'
						params={{ emailId: row.original.supportRequestEmailId ?? '' }}
						title={row.original.requestType}
						children={t(`help:requestTypes.${camelCase(row.original.requestType)}`)}
					/>
				),
			},
			{
				accessorKey: 'emailAddress',
				header: t('supportRequests.fields.emailAddress'),
				filter: {
					label: t('supportRequests.filters.emailAddress'),
					group: t('supportRequests.filterGroups.details'),
				},
				cell: ({ row }) => <Text>{row.original.emailAddress}</Text>,
			},
			{
				accessorKey: 'fullName',
				header: t('supportRequests.fields.fullName'),
				filter: {
					label: t('supportRequests.filters.fullName'),
					group: t('supportRequests.filterGroups.details'),
				},
				cell: ({ row }) => <Text>{row.original.fullName}</Text>,
			},
			{
				accessorKey: 'userName',
				header: t('supportRequests.fields.userName'),
				filter: {
					label: t('supportRequests.filters.userName'),
					group: t('supportRequests.filterGroups.details'),
				},
				cell: ({ row }) => <Text>{row.original.userName}</Text>,
			},
			{
				enableColumnFilter: false,
				accessorKey: 'description',
				header: t('supportRequests.fields.description'),
				cell: ({ row }) => <Text>{row.original.description}</Text>,
			},
			{
				accessorKey: 'createdOn',
				header: t('supportRequests.fields.createdOn'),
				sortingFn: 'datetime',
				filter: {
					group: t('supportRequests.filterGroups.details'),
					label: t('supportRequests.filters.createdOn'),
					variant: 'date-range',
				},
				cell: ({ row }) => (
					<FormattedDate value={row.original.createdOn} humanize size='sm' />
				),
			},
		],
	});

	return (
		<TableLayout title={t('supportRequests.title')} table={table}>
			<DataTable table={table} loading={supportRequestQuery.isFetching} />
		</TableLayout>
	);
}
